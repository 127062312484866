import { render, staticRenderFns } from "./importBuilding.vue?vue&type=template&id=2976b4ac&scoped=true&"
import script from "./importBuilding.vue?vue&type=script&lang=js&"
export * from "./importBuilding.vue?vue&type=script&lang=js&"
import style0 from "./importBuilding.vue?vue&type=style&index=0&id=2976b4ac&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2976b4ac",
  null
  
)

export default component.exports