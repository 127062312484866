<template>
  <div class="importBuilding">
    <import-template
      :uploadImgUrl="uploadImgUrl1"
      :downloadUrl="downloadUrl1"
      :showCloseBtn="false"
      :otherParams="otherParams1"
      label="企业基本信息"
    ></import-template>
    <import-template
      :hasHeader="false"
      :showCloseBtn="false"
      :uploadImgUrl="uploadImgUrl2"
      :downloadUrl="downloadUrl2"
      :otherParams="otherParams2"
      label="企业税源信息"
    ></import-template>
    <import-template
      :hasHeader="false"
      :uploadImgUrl="uploadImgUrl3"
      :downloadUrl="downloadUrl3"
      :otherParams="otherParams3"
      label="企业营收信息"
    ></import-template>
  </div>
</template>

<script>
import importTemplate from "@/components/bussiness/import-template/index";
export default {
  name: "importBuilding",
  data() {
    let _this = this;
    return {
      downloadUrl1: "/static/excel/企业基本信息模板.xlsx",
      downloadUrl2: "/static/excel/企业综合税源模板.xlsx",
      downloadUrl3: "/static/excel/企业营业收入模板.xlsx",
      uploadImgUrl1: "/gateway/hc-arch/buildingRegulate/import",
      uploadImgUrl2: "/gateway/hc-arch/buildingRegulate/import",
      uploadImgUrl3: "/gateway/hc-arch/buildingRegulate/import",
      otherParams1: {
        types: "1",
        tenantId: null,
      },
      otherParams2: {
        types: "2",
      },
      otherParams3: {
        types: "3",
      },
    };
  },
  components: {
    importTemplate,
  },
  mounted() {
    const { tenantId } = this.$route.query;
    if (tenantId) {
      this.otherParams1.tenantId = tenantId;
    }
    this.$setBreadList("企业信息导入");
  },
  methods: {
    submitSuccess(data) {
      return true;
    },

    submitBefore() {
      return true;
    },

    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
    },
  },
};
</script>
<style lang="less" scoped>
.importBuilding {
  box-sizing: border-box;
  height: 100%;
}
</style>
